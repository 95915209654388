<template>
  <div>
    <p class="text-2xl mb-6">Upload du CA consolidé</p>
    <v-card class="pt-7 pb-3">
      <v-row class="ma-3">
        <v-card-text>
          <v-form ref="siteForm">
            <v-row>
              <v-col cols="6">
                <date-filter
                  store="admin"
                  format="month"
                  :isRange="false"
                  periodType="past-only"
                  storeGetter="getMonthDate"
                  storeUpdater="updateMonthDate"
                ></date-filter>
              </v-col>

              <v-col cols="6">
                <div class="mb-4 d-flex align-center">
                  <v-file-input
                    accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    class="ml-3 mr-3"
                    outlined
                    label="conso_financiere.xlsx"
                    v-model="file"
                  />
                  <!-- @change="onFileChange" -->
                </div>
              </v-col>

              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="saveSite"
                >
                  Enregistrer les CA consolidés</v-btn
                >
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  :to="{ name: 'admin-list-site-ca' }"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import useAxios from "@/hooks/useAxios";
import router from "@/router";

import DateFilter from "@/components/common/filters/DateFilter";

export default {
  name: "UploadSiteConsolidatedCa",
  components: {
    DateFilter,
  },
  data() {
    return {
      editing: false,
      file: undefined,
    };
  },
  setup() {
    const { axiosPost } = useAxios();

    return {
      axiosPost,
    };
  },
  methods: {
    async saveSite() {
      if (!this.file) {
        alert("Please select a file first!");

        return;
      }

      const formData = new FormData();
      formData.append("month_date", this.monthDate);
      formData.append("file", this.file, this.file.name);
      const { status } = await this.axiosPost(
        "/sites/consolidated-ca/",
        formData
        /* {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        } */
      );
      if (status === 200) {
        await router.push({ name: "admin-list-site-ca" });
      }
    },
  },
  computed: {
    monthDate() {
      return this.$store.getters["admin/getMonthDate"];
    },
  },
};
</script>

<style scoped></style>
